export enum Environment {
  development = 'development',
  production = 'production',
  preview = 'preview',
}

const productionHosts = [
  'app.breakoutlearning.com',
  'cases.breakoutlearning.com',
  'beta.breakoutlearning.com',
  'beta-new.breakoutlearning.com',
]

const previewHosts = [
  'dev.breakoutlearning.com',
  'dev-new.breakoutlearning.com',
]

let _env: Environment | null = null

export function environment(): Environment {
  // Cache the environment
  if (_env) return _env

  _env = resolveEnvironment()

  return _env
}

export function inE2E(): boolean {
  return import.meta.env.MODE === 'e2e'
}

export function inBeta(): boolean {
  return window.location.hostname === 'beta.breakoutlearning.com'
}

export function inDevelopment(): boolean {
  return environment() === Environment.development
}

export function inProduction(): boolean {
  return environment() === Environment.production
}

export function inLocalDev(): boolean {
  return window.location.hostname === 'localhost'
}

function resolveEnvironment(): Environment {
  const hostname = window.location.hostname
  if (productionHosts.includes(hostname)) return Environment.production
  if (previewHosts.includes(hostname)) return Environment.preview

  // PR previews are hosted on *.web.app
  if (hostname.endsWith('.web.app')) return Environment.preview

  // otherwise, we're in development
  return Environment.development
}
